import React from "react"
import { Container, Flex, Section } from "../layoutComponents"
import { List, ImgWrapper } from "./serviceItems"
import Title from "../title"

import IndustrialServices from "../../images/services/industrial-services.png"

export default function Industrial(props) {
  return (
    <Section>
      <Container>
        <Title title="Industrial Electrician Toronto and GTA" />
        <Flex>
          <List className="spacing">
            <li>Power distribution</li>
            <li>Machinery moving and installations</li>
            <li>Shut down maintenance and service calls</li>
            <li>MCC and motor controls</li>
            <li>
              EMT and Rigid conduit including cable trays and wiring pulling
            </li>
            <li>Roof top units and more</li>
          </List>
          <ImgWrapper>
            <img
              src={IndustrialServices}
              alt="industrial electrical services ontario"
            />
          </ImgWrapper>
        </Flex>
      </Container>
    </Section>
  )
}
