import styled from "styled-components"

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    font-weight: var(--fw-500);
    font-size: var(--fs-b);
    line-height: 1.1;

    /* only use below if using image as before element */
    display: flex;
    align-items: center;

    &::before {
      /* content: "✓"; */
      content: url("../../../favicon.ico");
      font-size: 24px;
      margin-right: 0.8rem;
    }
  }
`

export const ImgWrapper = styled.div``
