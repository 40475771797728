import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import BannerActions from "../../components/banners/bannerActions"
import Industrial from "../../components/services/industrial"
import FeaturedProject from "../../components/gallery/featuredProject"
import CallToAction from "../../components/banners/callToAction"
import FeaturedTestimonial from "../../components/testimonials/featuredTestimonial"

import Featured from "../../images/projects/industrial/30-kva-transformer-distribution.jpg"
import Banner from "../../images/banner.png"
import ActionBanner from "../../images/action-banner.png"

export default function IndustrialServices() {
  return (
    <Layout>
      <SEO title="Industrial Electrician Toronto and GTA" />
      <BannerActions img={Banner} />
      <Industrial />
      <CallToAction
        img={ActionBanner}
        textPrimary="need an industrial electrician?"
        textSecondary=""
      />
      <FeaturedProject
        title="recent industrial project"
        description="30kVA transformer distribution"
        img={Featured}
        altText="Industrial Electrician Toronto and GTA"
      />
      <FeaturedTestimonial
        testimonial="Good contractors are really hard to find and I’ve been fortunate through referrals from people familiar with or in the trades. Royco Electric is not only reasonable in price, but James is upfront and honest from the start. His crew was professional and were fun to be around. If you need electrical work done in Toronto, this is the company you should reach out to."
        company="Homeowner"
        name="CRAIG HADLEY"
      />
    </Layout>
  )
}
