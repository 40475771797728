import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Section, Container } from "../layoutComponents"
import Title from "../title"
import SpecificProject from "./specificProject"

export default function FeaturedProject(props) {
  return (
    <Section>
      <Container>
        <Title title={props.title} />
        <SpecificProject
          img={props.img}
          alt={props.altText}
          description={props.description}
        />
      </Container>
    </Section>
  )
}
